

























































import {
  defineComponent, computed, onMounted, useContext,
} from '@nuxtjs/composition-api';
import {SfBottomModal, SfButton, SfCharacteristic, SfHeading, SfList, SkeletonLoader} from '~/components';
import { CountrySelectorItem, useCurrency } from '~/composables';
import { useCountriesStore } from "~/modules/countries/stores/countries";
import { storeToRefs } from "pinia";

export default defineComponent({
  name: 'CurrenciesModal',
  components: {
    SkeletonLoader,
    SfHeading,
    SfButton,
    SfList,
    SfBottomModal,
    SfCharacteristic,
  },
  props: {
    isModalOpen: Boolean,
    selectedCurrency: {
      type: String,
      default: '',
    },
  },
  emits: ['closeModal'],
  setup(props, { emit }) {
    const { app, $config } = useContext();
    const {
      currency: currencies,
      change: changeCurrency,
      load: loadCurrencies,
    } = useCurrency();
    const { countriesList } = storeToRefs(useCountriesStore());

    const availableCurrencies = computed<string[]>(() => currencies.value?.available_currency_codes || []);

    onMounted(() => {
      if (currencies.value && currencies.value?.available_currency_codes) return;
      loadCurrencies();
    });

    const currencySymbol = (currency) => {
      switch (currency) {
        case 'USD':
          return '$';
        case 'EUR':
          return '€';
        case 'AED':
          return '\tد.إ;';
        case 'SAR':
          return ' هللة';
        default:
          return '';
      }
    };

    const handleCurrency = async (currency) => {
      if (currency === 'SAR' || currency === 'AED') {
        const selectedCountry: CountrySelectorItem = countriesList.value.items.filter(country => country.currency_code === currency)[0];
        if (selectedCountry && selectedCountry.code && selectedCountry.icon_url) {
          app.$cookies.set('vsf-selected-country', selectedCountry.code, { path: `/${$config.websiteCode}`});
          app.$cookies.set('vsf-selected-country-icon', selectedCountry.icon_url, { path: `/${$config.websiteCode}`});
        }
      }

      await changeCurrency({id: currency });
    }

    const closeModal = () => {
      emit('closeModal');
    };

    return {
      countriesList,
      handleCurrency,
      changeCurrency,
      availableCurrencies,
      currencySymbol,
      closeModal,
    };
  },
});
